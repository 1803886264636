#sitecontent {
	text-align: center;
	padding: 30px 40px;
	background: #ccc;
}

#sitecontent button:hover {
	text-decoration: none !important;
	color: #fff !important;
}
.modal-content {
	border: none !important;
}
