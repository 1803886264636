.site-preview {
  min-height: 150px;
  width: 380px;
  max-width: 100%;
  max-height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#btn-manual-deploy {
  position: absolute;
  top: 1.5rem;
  right: 5.5rem;
  margin-bottom: 0;
  display: inline;
  padding: 0;
}