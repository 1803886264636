.form-container {
    padding: 75px 50px;
}
.form-group {
    position: relative;
    margin-bottom: 1.5rem !important;
}
.form-group .form-control {
  border: 1px solid #e1e1e1;
  background-color: transparent;
  padding: 10px 15px;
  height: 50px;
  resize: none;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  margin-bottom: 10px;
  color: inherit;
  transition: 500ms ease-in-out;
}

.form-group.active::after {
  transform: scaleX(1);
}

.form-group::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 300ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    pointer-events: none;
}

.form-group label {
  color: #4f4f4f;
  opacity: .85;
  position: absolute;
  top: 13px;
  left: 9px;
  padding: 0 7px;
  right: 0;
  cursor: text;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  display: inline-table;
  pointer-events: none;
  background: #ffffff;
  margin-bottom: 0;
}

.form-group .form-control:focus {
    box-shadow: none;
    background-color: transparent;
    border-color: var(--blue);
    
}

.form-group.active .form-control {
    border:2px solid #0c5dfe;
}

input.form-control:focus:not([readonly])+label,
textarea.form-control:focus:not([readonly])+label {
  opacity: 1;
  top: -12px;
  font-size: 13px;
  color: var(--blue);
  font-weight: 600;
}

.form-group.active label {
  opacity: 1;
  top: -12px;
  font-size: 13px;
  color: #0c5dfe;;
  font-weight: 600;   
}

.form-check {
    position: relative;
    display: block;
}

.form-check-inline .form-check-input[type=checkbox] {
    margin-left: -20px !important;
}

.form-check-input[type=checkbox]:checked, .form-check-input[type=checkbox]:not(:checked) {
    opacity: 0;
}

.form-check-input[type=checkbox] {
    position: absolute;
    margin-top: .3rem;
    height: 18px;
    width: 18px;
    margin-left: 0;
}

.form-check-input[type=checkbox]+label, label.btn input[type=checkbox]+label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    display: inline-block;
    height: 1.5625rem;
    line-height: 1.5625rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    top:0;
    left:0;
}

.form-check-input[type=checkbox]:checked+label:before, label.btn input[type=checkbox]:checked+label:before {
    top: -4px;
    left: -5px;
    width: 12px;
    height: 1.375rem;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #007bff;
    border-bottom: 2px solid #007bff;
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-origin: 100% 100%;
}

.form-check-input[type=checkbox]+label:before, .form-check-input[type=checkbox]:not(.filled-in)+label:after, label.btn input[type=checkbox]+label:before, label.btn input[type=checkbox]:not(.filled-in)+label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 2px solid #8a8a8a;
    border-radius: 1px;
    margin-top: 3px;
    transition: .2s;
}
.form-check-input[type=checkbox]:not(.filled-in)+label:after, label.btn input[type=checkbox]:not(.filled-in)+label:after {
    border: 0;
    transform: scale(0);
}