.loading-modal {
	background-color: #fff;
}

.loading-modal .modal-sm {
	height: 80%;
}

.loading-modal .modal-content {
	background-color: transparent !important;
	border: none !important;
}

.loading-container {
	width: 65px;
	margin: 0 auto;
	margin-top: 300px;
}

.lds-hourglass {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
}
.lds-hourglass:after {
	content: ' ';
	display: block;
	border-radius: 50%;
	width: 0;
	height: 0;
	margin: 6px;
	box-sizing: border-box;
	border: 26px solid #007bff;
	border-color: #007bff transparent #007bff transparent;
	animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
	0% {
		transform: rotate(0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	50% {
		transform: rotate(900deg);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	100% {
		transform: rotate(1800deg);
	}
}
