.site-list .card-heading {
  position: relative;
  z-index: 2;
}
.link-box {
  background: #ffffff;
}

.card {
  height: auto !important;
  width: auto !important;
  padding: 0 !important;
  margin-bottom: 25px;
  border-radius: 0px !important;
  z-index: 9;
}

.preview-url {
  top: 0;
  right: 0;
}
.preview-url h3 {
  ffont-size: 2.369em;
}

.site-title-container {
  position: relative;
  display: block;
}

.table-heading {
  font-weight: bold;
}

.b-colored {
  background: #0c5dfe;
}

@media (min-width: 300px) {
  .data-counter {
    font-size: 2em;
    color: #333;
    line-height: 1em;
    text-align: left;
  }
}

@media (min-width: 576px) {
  .data-counter {
    font-size: 2.5em;
    color: #333;
    line-height: 1em;
    text-align: left;
  }
}

.text-success {
  color: rgb(19, 220, 99) !important;
}

.color-unread {
  color: rgb(6, 88, 255);
}

.color-draft {
  color: rgb(255, 6, 126);
}

.bg-light {
  background: #f8f9fa !important;
}

.iconize {
  background: #eef1f8;
  border-radius: 100%;
  font-size: 1.5em;
  color: #0658ff;
  width: 60px;
  height: 60px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  transition: all 200ms ease-in-out;
}

@media (min-width: 300px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1440px;
  }
}

@media (min-width: 300px) {
  .text-date {
    color: #6c757d !important;
    font-size: 0.75em;
  }
}

@media (min-width: 300px) {
  .text-info {
    color: #6c757d !important;
  }
}

.card-subheading li {
  padding: 5px 15px;
}

.card-subheading li.active {
  background: #0658ff;
  color: #fff !important;
  box-shadow: 5px 7px 26px -5px #a6a6a7 !important;
  -webkit-box-shadow: 5px 7px 26px -5px #a6a6a7 !important;
  -moz-box-shadow: 5px 7px 26px -5px #a6a6a7 !important;
  -ms-box-shadow: 5px 7px 26px -5px #a6a6a7 !important;
}

.iconize.iconize-orange {
  background-color: #f9ae63;
  color: #ffffff;
}

.iconize.iconize-success {
  background-color: #4ae6c0;
  color: #ffffff;
}

.card.b-colored a {
  color: #fff !important;
}

.iconize.iconize-xs {
  width: 50px;
  height: 50px;
  font-size: 1em;
  line-height: 50px;
  font-weight: bold;
  margin-top: 0px;
}

.list-group.d-flex {
  width: 100%;
}

.card-footer {
  background: #ffffff !important;
  border: 0px !important;
}

.btn {
  font-weight: normal;
  font-family: Montserrat, sans-serif;
  text-transform: capitalize;
  letter-spacing: normal;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #0658ff !important;
  color: #fff;
}

.btn.btn-inverted {
  border: 1px solid #0658ff;
  color: #0658ff;
}

.btn.btn-inverted:hover {
  background: #0658ff;
  color: #fff !important;
}

@media (min-width: 300px) {
  h6,
  .h6 {
    font-size: 1.1em;
  }
}

@media (min-width: 576px) {
  h6,
  .h6 {
    font-size: 1.2em;
  }
}

@media (min-width: 992px) {
  h6,
  .h6 {
    font-size: 1.4em;
  }
}

@media (min-width: 1200px) {
  h6,
  .h6 {
    font-size: 1.2em !important;
  }
}

.data-preview p,
td,
li,
label {
  font-size: 14px;
}

.card .card-text-block {
  z-index: 9;
}

.dropdown-toggle.hide-caret:after {
  display: none;
}

.data-visits {
  margin-top: 15px;
  margin-bottom: 15px;
}

.text-small {
  font-size: 0.875em;
}

@media (min-width: 992px) {
  .site-list .card-heading {
    padding-bottom: 0;
  }
}

.card-body {
  padding-top: 0;
  padding-bottom: 0;
}

.card-body .list-group-item:hover .iconize {
  background: #cddeff;
}

table tr:hover {
  background: #eef1f8;
}

.btn.btn-primary:hover {
  background: transparent;
  border-color: #0658ff;
  color: #0658ff;
}

.card-body.show {
  padding-bottom: 1.5em !important;
}

.card-heading h6 {
  margin-bottom: 0px;
}

.list-group-item.d-flex {
  border: 0px;
}

.options {
  text-align: right;
}

.btn.btn-tool {
  padding-left: 1em;
  padding-right: 1em;
}

.btn.btn-tool {
  border-color: #6c7293;
}

.bg-colored {
  background-color: #0658ff;
  color: #fff;
}

.text-warning {
  color: #f58c11 !important;
}

.icon-nav {
  width: 24px;
}

.item-edit {
  text-align: right;
  vertical-align: middle;
}

.item-action {
  text-align: right;
}
