#site-plan .switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 40px;
}

#site-plan .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

#site-plan .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--success);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

#site-plan .slider:before {
  position: absolute;
  content: '';
  height: 34px;
  width: 34px;
  left: 3px;
  top: 0px;
  background-color: #fff;
  transition: 0.4s;
  bottom: 0;
  margin: auto 0;
}

#site-plan input:checked + .slider {
  background-color: var(--primary);
}

#site-plan input:checked + .slider:before {
  -webkit-transform: translateX(60px);
  -ms-transform: translateX(60px);
  transform: translateX(60px);
  background-color: #fff;
}

#site-plan .toggler {
  color: var(--color-grey4);
  font-size: 18px;
}

.checked-list,
.plan-table {
  list-style: none;
  padding: 0;
  margin-bottom: 0 !important;
}

.checked-list li {
  font-weight: 400;
  line-height: 28px;
  color: #333333;
  padding-left: 25px;
  display: block;
  background: url('../../../images/check-mark.svg') no-repeat left 5px;
  background-size: 18px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-top: 0 !important;
}

.plan-table li {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan-table li .tooltip-btn {
  position: absolute;
  right: 0;
  top: 6px;
  opacity: 0.85;
  display: block;
}

.w-15 {
  width: 15%;
}

.plan-detail {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricing-table thead th {
  vertical-align: super;
  position: relative;
}

.yearly-upgrade {
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  text-align: left;
  display: inline-block;
  padding-left: 5px;
}

.plan-pricing {
  font-size: 12px;
  display: inline-block;
}

.font-size-12 {
  font-size: 12px;
}

.table {
  margin-bottom: 0;
}

tbody tr td:nth-child(1) {
  background: linear-gradient(to bottom right, #28a745, #2bc14d);
  color: #fff;
}
tbody tr.pricing-footer {
  background: linear-gradient(to bottom right, #28a745, #2bc14d);
  color: #fff;
}
.tbl-annual tr td:nth-child(1) {
  background: linear-gradient(to bottom right, #0072ec, #007bff);
}
.tbl-annual tbody tr.pricing-footer {
  background: linear-gradient(to bottom right, #0072ec, #007bff);
  color: #fff;
}

.tbl-annual .monthly-price {
  display: none;
}
.annual-price {
  display: none;
}
.tbl-annual .annual-price {
  display: table-row;
}

.tbl-annual .monthly-subscription-only {
  display: none;
}

@media (min-width: 768px) {
  .plan-box .bordered-heading {
    height: 210px;
  }
}
