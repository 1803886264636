@media (min-width: 768px) {
  .breadcrumb-item, .dropdown-item {
    font-size: 1em !important;
  }
}

@media (min-width: 300px) {
  .breadcrumb-item, .dropdown-item {
    font-size: .875em;
  }
}

@media (min-width: 300px) {
  .btn.btn-default {
    background: #ffffff;
    border: 1px solid #eef1f8;
  }
}

@media (min-width: 992px) {
  .btn.btn-default {
    padding: 12px 30px;
  }
}

@media (min-width: 300px) {
  .btn {
    font-size: 0.875em;
    padding: 12px 15px;
  }
}

@media (min-width: 576px) {
  .btn {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 1em;
  }
}

@media (min-width: 992px) {
  .btn {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 1em;
  }
}

@media (min-width: 300px) {
  p {
    font-size: 0.875em;
    line-height: 1.4em;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  p {
    font-size: 1em;
    line-height: 1.7;
    margin-bottom: 15px;
  }
}

@media (min-width: 300px) {
  .card-border {
    border: 0px;
  }
}

@media (min-width: 576px) {
  .card-border {
    border-right: 1px solid #eef1f8;
    padding-right: 25px;
  }
}

@media (min-width: 300px) {
  #options a {
    font-size: 0.875em;
  }
}

@media (min-width: 992px) {
  #options a {
    font-size: 1em;
  }
}

@media (min-width: 300px) {
  .logo-container img {
    width: 50px;
  }
}

@media (min-width: 1200px) {
  .logo-container img {
    width: 60px;
  }
}

@media (min-width: 992px) {
  .header-bottom {
    padding-top: 6em !important;
  }
}

@media (min-width: 768px) {
  .header-bottom {
    padding-top: 4em;
  }
}

@media (min-width: 300px) {
  .header-bottom {
    padding-top: 3em;
  }
}

@media (min-width: 992px) {
  #main {
    margin-top: 3em;
  }
}

@media (min-width: 300px) {
  #main {
    margin-top: 1em;
  }
}

