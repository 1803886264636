.accordion-setting .btn-none::after{
  content: "\f141";
  float: right;
  font-family: "Font Awesome 5 Free";
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-top: 0px;
}
.long {
  word-break: break-all;
}
