/*  Grow  */

.card-resizer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: inherit;
  transition: transform 200ms ease 100ms;
}

.card:hover .card-resizer {
  transform: scale(1.030);
}

