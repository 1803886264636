* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 15px;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

body {
  font: 15px/1.7 'Open Sans', sans-serif !important;
  font-weight: 400;
  color: #031121;
  padding-right: 0px !important;
  background-color: #f9f9f9;
  padding-top: 75px !important;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: 'Montserrat', sans-serif !important;
  line-height: 1.15;
  text-transform: unset;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal !important;
}

h6,
.h6 {
  font-family: 'Montserrat', sans-serif !important;
  line-height: 1.4;
  text-transform: unset;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal !important;
}

.font-weight-medium {
  font-weight: 600;
}

a {
  color: #031121;
}
a:hover {
  text-decoration: none;
  color: inherit !important;
}

:hover {
  transition: 400ms ease-in-out;
}

.shadow:hover {
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.3) !important;
}

textarea.form-control {
  height: auto !important;
}

.component {
  padding: 100px 0;
}

.text-success {
  color: rgb(19, 220, 99) !important;
}
.bg-success {
  background-color: rgb(19, 220, 99) !important; 
}
.text-base {
  color: var(--blue);
}

.bg-base {
  background-color: var(--blue) !important;
}
.b-colored {
  background-color: #0c5dfe;
}
.bg-white:hover {
  background-color: #cddeff;
}

.bg-orange {
  background-color: #f58c11;
}

.bordered-heading {
  border-bottom: 1px solid #e1e1e1;
}

.font-size-12 {
  font-size: 12px;
}

header .dropdown button::after {
  display: none;
}

.card-heading,
.card-body,
.card-footer {
  padding: 1.5em 2em;
  z-index: 1;
}

.card-body {
  padding-top: 0;
  padding-bottom: 0;
}

.card-link {
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card .dropleft button::before,
.card .dropdown button::after {
  display: none;
}

.card .dropleft,
.card .dropdown {
  z-index: 1;
}

.card,
.shadow {
  box-shadow: 5px 7px 26px -5px #dbdbee !important;
  -webkit-box-shadow: 5px 7px 26px -5px #dbdbee !important;
  -moz-box-shadow: 5px 7px 26px -5px #dbdbee !important;
  -ms-box-shadow: 5px 7px 26px -5px #dbdbee !important;
  border: none;
}

.site-status {
  position: relative;
  font-size: 14px;
  font-weight: 600;
}

.status {
  width: 13px;
  height: 13px;
  margin-right: 0.25rem;
}

.data-preview p {
  font-size: 14px;
  font-weight: 400;
}

.data-preview span {
  font-size: 18px;
}

.advance-setting .dropdown-toggle::after {
  display: none;
}

.link-box a {
  padding: 2rem;
}

/*Overview*/

.profile-img {
  margin: 0 auto;
  height: 180px;
  width: 180px;
  background-color: #f9f9f9;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #007bff;
  font-size: 84px;
  font-weight: 500;
}
/*Form css*/

#search-form .form-group {
  margin-bottom: 0 !important;
}

#search-form .form-group .form-control {
  margin-bottom: 0;
  height: auto;
  padding-left: 0;
}
.centered-captcha {
  width: 304px;
  margin: 0 auto;
}

.hyphens-auto {
  hyphens: auto;
}

/*.btn-primary{
  background-color: var(--blue);
  border-color: var(--blue);
}
.btn-primary:hover {
  background-color: #031121;
  border-color: #031121;
}*/

.btn-primary.btn-inverted {
  background: #fff;
  color: #031121;
  border-color: #fff;
}
.btn-primary.btn-inverted:hover {
  border-color: #cddeff;
  background-color: #cddeff;
}
.btn-primary.btn-bordered {
  background: transparent;
  border: 2px solid #fff;
}

.btn-primary.btn-bordered:hover {
  border-color: #cddeff;
  background-color: #cddeff;
  color: #031121;
}

.btn.btn-orange {
  background-color: #f58c11;
  border-color: #f58c11;
}

.btn.btn-orange:hover {
  background-color: #f55c11;
  border-color: #f55c11;
}

.btn-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: transparent !important;
  border: none !important;
}
.btn-outline-orange {
  color: #f55c11;
  border-color: #f55c11;
}
.btn-outline-orange:hover {
  color: #fff;
  background-color: #f55c11;
  border-color: #f55c11;
}
.btn-outline-orange:not(:disabled):not(.disabled):active,
.btn-outline-orange:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange.dropdown-toggle {
  color: #fff;
  background-color: #f55c11;
  border-color: #f55c11;
}

.btn.aslink {
  font-family: 'Open Sans', sans-serif;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
.btn.aslink::after {
  display: none;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eee;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 25px;
  width: 25px;
  left: 0px;
  top: 0px;
  background-color: #ddd;
  transition: 0.4s;
  bottom: 0;
  margin: auto 0;
}

input:checked + .slider {
  background-color: #eee;
}

/*input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}*/

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: var(--blue);
}

/* Rounded sliders */
.slider.round {
  border-radius: 100px;
}

.slider.round:before {
  border-radius: 100%;
}

.accordion {
  position: relative;
}

/*Jun*/

.item-name {
  text-align: center;
}

.site-list .card .card-body {
  text-align: center;
}

.site-list .card-footer {
  padding-top: 1em;
}

.site-list .item-plan {
  z-index: 3;
  position: relative;
}

.site-title-container:after {
  content: '';
  clear: both;
  display: block;
}

.iconize-blue {
  background: #0658ff;
  border-radius: 100%;
  font-size: 1.5em;
  color: #eef1f8;
  width: 60px;
  height: 60px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  transition: all 200ms ease-in-out;
}
.billing-status {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.billing-status i {
  font-size: 25px;
  color: #0658ff;
  cursor: pointer;
}

.iconize-inverted {
  background: transparent;
  border: 2px solid #5d90f9;
  color: #fff;
}

.btn.btn-warning {
  background: #f58c11;
  color: #fff;
  border-color: #f58c11;
}

.card.invitation {
  background: #ffffff;
}

.badge-success {
  color: #fff;
  background-color: rgb(19, 220, 99) !important;
}

.item-content {
  background: #f7fafc;
  padding: 1.2em 2.3em;
  margin-top: 0.75em;
}

@media (max-width: 1023px) {
  .webriq-recaptcha {
    transform: scale(0.75);
    transform-origin: 0 center;
  }
}

@media (max-width: 1023px) {
  .centered-captcha .webriq-recaptcha {
    transform-origin: center;
  }
}
@media (max-width: 767px) {
  .form-container {
    padding: 30px;
  }
}

@media (min-width: 300px) {
  .site-list .item-logo {
    margin: 35px auto 10px auto !important;
    height: 60px;
    width: 60px;
    background: #eef1f8;
    border-radius: 100%;
    line-height: 60px;
  }
}

@media (min-width: 300px) {
  .site-list .card {
    min-height: auto;
  }
}

@media (min-width: 300px) {
  .h6,
  h6 {
    font-size: 1em;
  }
}

@media (min-width: 300px) {
  .item-logo-text {
    display: block;
    font-size: 2em;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

@media (min-width: 300px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .site-list .item-logo {
    margin: 25px auto 10px auto !important;
    height: 60px;
    width: 60px;
    background: #eef1f8;
    border-radius: 100%;
  }
}

@media (min-width: 768px) {
  .item-logo-text {
    font-size: 1.5em;
  }
}

@media (min-width: 768px) {
  .link-box a {
    padding: 5rem 2rem;
  }
  h1,
  .h1 {
    font-size: 4.209em;
  }

  h2,
  .h2 {
    font-size: 3.157em;
  }

  h3,
  .h3 {
    font-size: 2.369em;
  }

  h4,
  .h4 {
    font-size: 1.777em;
  }

  h5,
  .h5 {
    font-size: 1.333em !important;
  }
}

@media (min-width: 768px) {
  .site-list .item-logo {
    margin: 20px auto 10px auto !important;
    height: 50px;
    width: 50px;
    background: #eef1f8;
    border-radius: 100%;
  }
}

@media (min-width: 768px) {
  .site-list .card {
    min-height: 320px;
  }
}

@media (min-width: 768px) {
  .site-list .card-footer {
    padding-top: 0px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1440px;
  }
}
@media (min-width: 992px) {
  .site-list .item-logo {
    margin: 35px auto 25px auto !important;
    height: 70px;
    width: 70px;
    background: #eef1f8;
    border-radius: 100%;
  }
}

@media (min-width: 992px) {
  .site-list .card {
    min-height: 360px;
    margin-bottom: 2rem !important;
  }
}

.hide {
  display: none;
}

.note {
  padding: 25px 45px;
  background: #f5f5f5;
  border-radius: 6px;
  font-size: 15px;
  margin-bottom: 35px;
}

.note .note-title {
  font-size: 24px;
  font-weight: 400 !important;
  color: #ef9a9a;
  margin-bottom: 15px;
}

.note .note-description {
  line-height: 24px;
  margin-bottom: 15px;
}

.note .note-description a {
  color: #00bfa5;
  font-weight: 600 !important;
}

.steps.grey {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
}

.steps.grey strong {
  color: #000000;
}

.steps.grey > li {
  padding-top: 10px;
  padding-left: 65px;
  margin-left: 0;
  margin-bottom: 65px;
}

.steps.grey li li {
  margin-bottom: 15px;
}

.steps.grey li h3 {
  font-size: 24px;
  font-weight: 300 !important;
  margin-bottom: 30px;
}

.steps.grey li a {
  font-weight: bold;
  color: #000;
}

.steps.grey li a:hover {
  color: #2196f3;
}

.steps.grey .step-number {
  display: inline-block;
  width: 45px;
  height: 45px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  line-height: 45px;
  border-radius: 45px;
  background-color: #f5f5f5;
  margin-top: -5px;
  margin-left: -65px;
  float: left;
  color: #000;
}

:root {
  --success: rgb(19, 220, 99);
  --color-grey4: #d4d9dd;
};

