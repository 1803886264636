.modal .modal-header {
	padding: 0;
}
.modal .modal-header .close {
	font-family: 'Open sans', sans-serif;
	font-weight: 400;
	position: absolute;
	top: 0;
	right: 0;
	height: 35px;
	width: 35px;
	z-index: 1;
	padding: 0.25rem;
	border-radius: 100%;
	background-color: #f58c11;
	color: #fff;
	opacity: 1;
	font-size: 20px;
	outline: none;
}

@media (min-width: 992px) {
	.modal-md {
		max-width: 700px !important;
	}
}
