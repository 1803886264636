#template-selection legend {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

#template-selection input[type='radio'] {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
#template-selection label {
  border: none;
}
#template-selection input[type='radio'] + label {
  opacity: 0.7;
}
#template-selection input[type='radio']:checked + label {
  border: 2px solid #7549a2;
  opacity: 1 !important;
  border-radius: 5px;
  box-shadow: 2px 2px 0px 1px #f5d2f2;
  transition: 0.2s border;
}
#template-selection .formchecked {
  display: none;
}
#template-selection input[type='radio']:checked + label .formchecked {
  position: absolute;
  right: 15px;
  bottom: 50px;
  display: block !important;
}
